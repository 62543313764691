import * as React from 'react';
import { Box, Typography, TextField } from '@mui/material'
import { ComputedLink, ComputedNode, ResponsiveTree } from '@nivo/tree'
import { MouseEvent } from 'react'
import Title from './Title'
import { TaskType, TaskTreeNodeDataType } from './Interfaces';
import { title } from 'process';


export default function TaskTree({Tasks, CreateTaskFn}:{Tasks:TaskType[], CreateTaskFn:Function})
{

  const [DataTree, setDataTree] = React.useState<TaskTreeNodeDataType>({});
  const [selectedNodeId, setselectedNodeId] = React.useState<string>("");

  React.useEffect(()=>{console.log(DataTree)},[DataTree]); 

  function ConstructDateTree()
  {
    let NodesArray: TaskType[] = [];
    let ProcessedNodePath: TaskType[] = [];

    console.log(Tasks)
    if(!Tasks.some(task=>(task.id === undefined)))
    {
      NodesArray.push( Tasks.find(task=>(task.ParentNodeId== "root") )! );
      let data: TaskTreeNodeDataType = {id:NodesArray[0]?.id, name: NodesArray[0]?.title, color:"rgba(255, 0, 0, 1)"}
  
  
      while(NodesArray.length>0)
      {
          NodesArray = NodesArray.concat( Tasks.filter(task=>(task.ParentNodeId == NodesArray[0].id)) );
          console.log(NodesArray)

          let taskNodeIterator: TaskType = NodesArray[0];
          ProcessedNodePath = [];
          while(taskNodeIterator.ParentNodeId !== "root")
          {
            ProcessedNodePath.push(taskNodeIterator);
            taskNodeIterator = Tasks.find(task=>(task.id == taskNodeIterator.ParentNodeId))!;
          }
          ProcessedNodePath.reverse();
      
          let targetDataTreeNode: TaskTreeNodeDataType = data;
          targetDataTreeNode = data;
          ProcessedNodePath.forEach(step=>{
          targetDataTreeNode = targetDataTreeNode.children?.find(node=>(node.id == step.id))!
      })
      
          targetDataTreeNode.children =
          (NodesArray.filter(node=>(node.ParentNodeId == NodesArray[0].id))
          .map(node=>({id:node?.id, name: node?.title, color:"rgba(0, 255, 0, 1)"}as TaskTreeNodeDataType)))
          NodesArray = NodesArray.slice(1, undefined);
        }
      setDataTree(data);  
    }
  }


  React.useEffect(() =>{
    if(Tasks.length>0)
    {
      ConstructDateTree();
    }
  },
  [Tasks]
  );

  React.useEffect(() =>{
    console.log(DataTree)
  },
  [DataTree]
  );

  React.useEffect(() =>{
    console.log(selectedNodeId)
  },
  [selectedNodeId]
  );
  

  let clickCount=0;

  function DoubleClickHandler(node:ComputedNode<TaskTreeNodeDataType>)
  {

      if(clickCount==0)
      {
          setTimeout(() => {

              if(clickCount==1)
              {
                setselectedNodeId(node.data.id!)
              }
              else if(clickCount==2)
              {
                CreateTaskFn(node.data.id);
              }
              else
              {
              }
              clickCount=0;
            }, 200);    

      }
      clickCount++;
  }



  return(
    <Box sx={{height:500, width:'80vw'}}>
      <Typography variant="caption" sx={{width:'50%', textAlign:"center"}}>This is the Project Task tree, click on a task to select it, double click to create a child Task.</Typography>
      <ResponsiveTree
        data={DataTree}
        identity="name"

        nodeSize={(node)=>{
          return (selectedNodeId==node.data.id) ? 55:30;
        }}

        activeNodeSize={(node)=>{
          return (selectedNodeId==node.data.id) ? 55:40;
        }}

        inactiveNodeSize={(node)=>{
          return (selectedNodeId==node.data.id) ? 55:30;
        }}

        nodeColor={{ scheme: 'tableau10' }}
        fixNodeColorAtDepth={1}
        linkThickness={2}
        activeLinkThickness={8}
        inactiveLinkThickness={2}
        linkColor={{
          from: 'target.color',
          modifiers: [
            [
              'opacity',
              0.4
            ]
          ]
        }}
        margin={{ top: 90, right: 90, bottom: 90, left: 90 }}
        motionConfig="stiff"
        meshDetectionRadius={80}
        orientLabel={false}
        onNodeClick = {(node: ComputedNode<TaskTreeNodeDataType>) => { DoubleClickHandler(node) } }
        onNodeMouseEnter = {(node: ComputedNode<TaskTreeNodeDataType>) => {  } }
        highlightAncestorNodes={false} 
          // @ts-ignore
          onLinkMouseEnter={function (node: ComputedLink<{ name: string; color: string; children: { name: string; color: string; children: { name: string; color: string; }[]; }[]; }>, event: MouseEvent): void {
          throw new Error('Function not implemented.');
        } } 
          // @ts-ignore
          onLinkMouseMove={function (node: ComputedLink<{ name: string; color: string; children: { name: string; color: string; children: { name: string; color: string; }[]; }[]; }>, event: MouseEvent): void {
          throw new Error('Function not implemented.');
        } } 
          // @ts-ignore
          onLinkMouseLeave={function (node: ComputedLink<{ name: string; color: string; children: { name: string; color: string; children: { name: string; color: string; }[]; }[]; }>, event: MouseEvent): void {
          throw new Error('Function not implemented.');
        } } 
          // @ts-ignore
          onLinkClick={function (node: ComputedLink<{ name: string; color: string; children: { name: string; color: string; children: { name: string; color: string; }[]; }[]; }>, event: MouseEvent): void {
          throw new Error('Function not implemented.');
          // @ts-ignore
        } } linkTooltip={undefined} linkTooltipAnchor={'top'}        
        
        />

      </Box>
  )
}