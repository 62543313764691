import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { Link } from 'react-router-dom';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material';
import { projectMembershipType, ProjectType, SkillType, TaskType } from './Interfaces';
import {auth, db} from "../firebase"
import { collection, getDocs, query } from 'firebase/firestore';
import { useGlobalState as useFirebaseGlobalState } from "./Settings" ;
import { useAuthState } from 'react-firebase-hooks/auth';
import TableContainer from '@mui/material/TableContainer';
import TasksTable from './TasksTable';
import TasksDataTable from './TasksDataTable';


export default function Tasks() {

  const [Projects, setProjects] = React.useState<ProjectType[]>();
  const [ProjectsMemberships, setProjectsMemberships] = React.useState<projectMembershipType[]>([]);
  const [Tasks, setTasks] = React.useState<TaskType[]>();
  const [FilteredTasks1, setFilteredTasks1] = React.useState<TaskType[]>();
  const [FilteredTasks2, setFilteredTasks2] = React.useState<TaskType[]>();

  const [user, loading, error] = useAuthState(auth);
  let currentUserId = user?.uid;
  let currentUserName = user?.displayName??"";
  React.useEffect(() =>{
      currentUserId = user?.uid;
      currentUserName = user?.displayName??"";
  },
  [user]
  );

  const [taskFilter, setTaskFilter] = 
  React.useState
  <(
    "AllTasksInPublicProjects"|
    "TasksYouAuthored"|
    "TasksAssignedToYou"|
    "AllTasksInProjectsYouJoined"|
    "UnassignedTasksInProjectsYouJoined"
    )>("AllTasksInPublicProjects");

  const [skills, setSkills] = React.useState<SkillType[]>([]);
  const [SkillFilter, setSkillFilter] = React.useState<string>("AllSkills");
  const [useFirebase] = useFirebaseGlobalState('useFirebase');
  

  const handleTaskFilterChange = (e:any) => {
    setTaskFilter(e.target.value);
  };
  
  const handleSkillFilterChange = (e:any) => {
    setSkillFilter(e.target.value);
  };
  

  React.useEffect(() =>{

    if(taskFilter == "AllTasksInPublicProjects")
    {
      setFilteredTasks1(Tasks?.filter(task=> Projects?.find(project=> project.id==task.project?.projectID)?.visibility == "public"));
    }
    else if(taskFilter == "TasksYouAuthored")
    {
      setFilteredTasks1(Tasks?.filter(Task => (Task.author?.authorId==currentUserId)));
    }
    else if(taskFilter == "TasksAssignedToYou")
    {
      setFilteredTasks1(Tasks?.filter(Task => (Task.assignee?.assigneeId==currentUserId)));
    }
    else if(taskFilter == "AllTasksInProjectsYouJoined")
    {
      setFilteredTasks1(Tasks?.filter(Task => 
        {
          let filterdMemnerships = ProjectsMemberships.filter(membership=>(membership.projectId==Task.project?.projectID))
          if(filterdMemnerships?.find(member => (member.userId == currentUserId)) !== undefined)        
          {return true;}
          else
          {return false;}
        }
        ));
    }
    else if(taskFilter == "UnassignedTasksInProjectsYouJoined")
    {
      setFilteredTasks1(Tasks?.filter(Task => 
        {
          if(Task.status!=="Unassigned")
          {return false;}
          else{
            let filterdMemnerships = ProjectsMemberships.filter(membership=>(membership.projectId==Task.project?.projectID))
            if(filterdMemnerships?.find(member => (member.userId == currentUserId)) !== undefined)   
            {return true;}
            else
            {return false;}
          }
        }
        ));
    }
  },
  [taskFilter, SkillFilter, Tasks, Projects]
  );

  React.useEffect(() =>{
    if(SkillFilter !== "AllSkills")
    {
      setFilteredTasks2(FilteredTasks1?.filter(task=>(task?.skill == SkillFilter)))
    }
    else
    {
      setFilteredTasks2(FilteredTasks1);
    }

  },
  [FilteredTasks1]
  );

  function loadDB()
  {

    const fetchProjects = async () => {
    const querySnapshot = await getDocs(collection(db, "Projects"));

    let Projects:ProjectType[]=[];
    querySnapshot.docs.forEach(doc=>{
        let Project:ProjectType;
        Project=doc.data() as ProjectType;
        Project.id = doc.id;
        Projects.push(Project);
      })
      setProjects(Projects)
    }
    fetchProjects().catch(console.error);   

    const fetchProjectsMemberships = async () => {
      const q = query(collection(db, "ProjectsMemberships"));
      const querySnapshot = await getDocs(q);
        let projectsMemberships:projectMembershipType[]=[];
        querySnapshot.docs.forEach(doc=>{
          let projectMembership:projectMembershipType;
          projectMembership=doc.data() as projectMembershipType;
          projectMembership.id = doc.id;
          projectsMemberships.push(projectMembership);
        })
        setProjectsMemberships(projectsMemberships)
      }
    fetchProjectsMemberships().catch(console.error);  


    const fetchTasks = async () => {
    const querySnapshot = await getDocs(collection(db, "Tasks"));

    let Tasks:TaskType[]=[];
    querySnapshot.docs.forEach(doc=>{
          let Task:TaskType;
          Task=doc.data();
          Task.id = doc.id;
          Tasks.push(Task);
        })
        setTasks(Tasks);
      }
      fetchTasks().catch(console.error);   


      const fetchSkills = async () => {
      const querySnapshot = await getDocs(collection(db, "Skills"));
      let Skills:SkillType[]=[];
      querySnapshot.docs.forEach(doc=>{
        let Skill:SkillType;
        Skill=doc.data();
        Skill.id = doc.id;
        Skills.push(Skill);
        })
        Skills.sort((a:SkillType, b:SkillType) => a.title?.localeCompare(b.title??"")??0);
        setSkills(Skills);
      }

      fetchSkills().catch(console.error);  

  }

  React.useEffect(loadDB,
  []
  );

  
  return (
<Container>

  <Button sx={{textTransform: 'none', m:2,  alignItems: 'center'}} variant="outlined"
  component={(props:any) => <Link {...props} to="/Home/CreateTask" />}>Start Tasks</Button>

  <Paper elevation={0} 
      sx={{
          p: 2,
          my: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent:'space-between',
          borderRadius: 5
      }}>


        <Box sx={{my:1, width:'45%'}}>
        <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">Task Filter</InputLabel>
            <Select
            labelId="taskfilter-select-label"
            id="taskfilter-select"
            value={taskFilter||''}
            label="taskfilter"
            onChange={handleTaskFilterChange}         
            >
              <MenuItem value={"AllTasksInPublicProjects"}>All Tasks In Public Projects</MenuItem>
              <MenuItem value={"TasksYouAuthored"}>Tasks You Authored</MenuItem>
              <MenuItem value={"TasksAssignedToYou"}>Tasks Assigned To You</MenuItem>
              <MenuItem value={"AllTasksInProjectsYouJoined"}>All Tasks In Projects You Joined</MenuItem>
              <MenuItem value={"UnassignedTasksInProjectsYouJoined"}>Unassigned Tasks In Projects You Joined</MenuItem>
            </Select>
        </FormControl>   
        </Box>

        <Box sx={{my:1, width:'45%'}}>
        <FormControl fullWidth >
            <InputLabel id="skill-simple-select-label">Skill Filter</InputLabel>
            <Select
            labelId="skillfilter-select-label"
            id="skillfilter-select"
            value={SkillFilter||''}
            label="Skillfilter"
            onChange={handleSkillFilterChange}         
            >
              <MenuItem value={"AllSkills"}>All Skills</MenuItem>
              {skills.map(skill => <MenuItem value={skill.title}>{skill.title}</MenuItem>)}
            </Select>
        </FormControl>   
        </Box>

      </Paper>


      <Paper elevation={0} 
      sx={{
          p: 2,
          my:2,
          overflow: 'scroll',
          borderRadius: 5
      }}>
            <TasksTable Tasks={FilteredTasks2!}/>
            {/* <TasksDataTable Tasks={FilteredTasks2!}/> */}
  </Paper>

</Container>

);
}
