import { Autocomplete, Box, Button, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Paper, Rating, Select, Table, TableBody, TableCell, TableRow, TextareaAutosize, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import Title from "./Title";
import React, { useEffect } from "react";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from "@mui/lab";
import {TaskType, UserType, ProjectType, RatingItem, projectMembershipType, TransactionType, TaskStatusType, ErrorLogType} from './Interfaces';
import { useHistory, useParams } from "react-router-dom";
import {auth, db} from "../firebase"
import Switch from '@mui/material/Switch';
//import { typography } from "@mui/system";
import { addDoc, collection, doc, documentId, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useGlobalState as useFirebaseGlobalState } from "./Settings" ;
import { useAuthState } from "react-firebase-hooks/auth";
import useState from 'react-usestateref';
import { Network } from "@nivo/network";
import NetworkChart from "./StateChart";
import StateChart from "./StateChart";
import TaskTimelineChart from "./TaskTimelineChart";
import DescriptionPanel from "./TaskDescriptionPanel";
import TaskTree from "./TaskTree";
import Project from "./Project";

export default function CreateOrEditTask({IsCreateNotEdit, setDatabaseStatusRequest, setDatabaseErrorDesc}:
    {IsCreateNotEdit:boolean, setDatabaseStatusRequest:Function, setDatabaseErrorDesc:Function})
{
    const history = useHistory();
    
    let { id } = useParams<{id: string}>();

    const [task, setTask, taskRef] = useState<TaskType>({});
    const [Tasks, setTasks, TasksRef] = useState<TaskType[]>([]);
    const [Projects, setProjects] = useState<ProjectType[]>([]);
    const [ProjectsMemberships, setProjectsMemberships] = useState<projectMembershipType[]>();
    //const [selectedProject, setselectedProject] = useState<ProjectType>();
    const [AssigneeUser, setAssigneeUser] = useState<UserType>();
    const [newTransactions, setNewTransactions] = useState<TransactionType[]>([]);
    const [taskNumber, setTaskNumber] = useState<number>(0);


    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    useEffect(() =>{
        currentUserId = user?.uid;
        currentUserName = user?.displayName??"";
    },
    [user]
    );

    useEffect(() =>{
        console.log(Tasks)
    },
    [Tasks]
    );

    useEffect(() =>{
        loadDB();
    },
    [task.project]
    );

    const [useFirebase] = useFirebaseGlobalState('useFirebase');
    const IsAuthor = task?.author?.authorId==currentUserId ? true:false;
    const IsAssignee = task?.assignee?.assigneeId==currentUserId ? true:false;

    const [saveButtonText, setsaveButtonText] = useState<string>("Save");
    const [saveButtonState, setsaveButtonState] = useState<("primary" | "success")>("primary");

    let IsMember:boolean = false;
    if(ProjectsMemberships?.find(member => (member.userId == currentUserId))?.status == "active")
    {IsMember=true;}

    const handleDeadlineChange = (newValue:any) => {
        setTask({...task, deadline:newValue.toDateString(), isDirty:true});
        setDatabaseStatusRequest("Sync");
    };

    const handleProjectSelection = (e:any) => {
        let localSelectedProject = Projects?.find(project=> project.projectTitle==e.target.textContent);
        //setselectedProject(Projects?.find(project=> (project.projectTitle==e.target.value)));
        setTask({...task, project:{projectID : localSelectedProject?.id, projectName: localSelectedProject?.projectTitle}});
      };

      const handleSkillSelection = (e:any) => {
          setTask({...task, skill: e.target.value, isDirty:true});
          setDatabaseStatusRequest("Sync");
      };

    const handleAssigneeSelection = (e:any) => {
        //let selectedAssignee = selectedProject?.projectMembers?.find(member=> member.name==e.target.value);
        let selectedAssignee = ProjectsMemberships?.find(member=> member.memberName==e.target.value);
        setTask({...task, assignee:{assigneeId : selectedAssignee?.userId, assigneeName: selectedAssignee?.memberName}, status:"Active"});
    };


    function HandleAssignMember(memberId:string, memberName:string)
    {
        setTask({...task, assignee:{assigneeId:memberId, assigneeName:memberName}, status:"Active"});
    }  
    
    function loadDB()
    {
        const fetchProjects = async () => {
        const querySnapshot = await getDocs(collection(db, "Projects"));
        let Projects:ProjectType[]=[];
        querySnapshot.docs.forEach(doc=>{
            let Project:ProjectType;
            Project=doc.data() as ProjectType;
            Project.id = doc.id;
            Project.isDirty = false;
            Projects.push(Project);
        })
        setProjects(Projects)
        }
        fetchProjects().catch((e)=>{ErrorHandler(e, "fetchProjects");}).then(()=>{setDatabaseStatusRequest("OK");});  


        const fetchProjectsMemberships = async () => {
            const q = query(collection(db, "ProjectsMemberships"), where("userId", "==", currentUserId));
            const querySnapshot = await getDocs(q);
              let projectsMemberships:projectMembershipType[]=[];
              querySnapshot.docs.forEach(doc=>{
                let projectMembership:projectMembershipType;
                projectMembership=doc.data() as projectMembershipType;
                projectMembership.id = doc.id;
                projectsMemberships.push(projectMembership);
              })
              setProjectsMemberships(projectsMemberships)
            }
        fetchProjectsMemberships().catch((e)=>{ErrorHandler(e, "fetchProjectMemberships");}).then(()=>{setDatabaseStatusRequest("OK");});


        const fetchTasks = async () => {
            const q = query(collection(db, "Tasks"), where("project.projectID", "==", task.project?.projectID));
            const querySnapshot = await getDocs(q);
      
            let Tasks:TaskType[]=[];
            querySnapshot.docs.forEach(doc=>{
              let Task:TaskType;
              Task=doc.data();
              Task.id = doc.id;
              Task.isDirty = false;
              Tasks.push(Task);
            })
            setTasks(Tasks);
          }
        fetchTasks().catch((e)=>{ErrorHandler(e, "Fetch Tasks")}).then(()=>{setDatabaseStatusRequest("OK");});


        if(!IsCreateNotEdit)
        {
            const fetchTask = async () => {
            const docRef = doc(db, "Tasks", id);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                let Task:TaskType;
                Task=docSnap.data();
                Task.id = docSnap.id;
                Task.isDirty = false;
                setTask(Task);
            } else {
                // doc.data() will be undefined in this case
            }
            }
            fetchTask().catch((e)=>{ErrorHandler(e, "fetchTask");}).then(()=>{setDatabaseStatusRequest("OK");});
        }
      }
      
    function loadDBPostTaskLoad()
    {
        if(IsCreateNotEdit && task.status==undefined && task.assignee==undefined && task.deadline==undefined)
        {
            setTask({...task, 
                status:"Unassigned", 
                created:new Date().toDateString(),
                deadline:new Date().toDateString(), 
                author:{authorId:currentUserId, authorName:currentUserName},
                compensation:{means:"both", billing:'fixed', calculation:'rating'}
            });
                
        }


        if(!IsCreateNotEdit && AssigneeUser==undefined && task.assignee?.assigneeId!==undefined)
        {
            const fetchUser = async () => {
            const docRef = doc(db, "Users", task.assignee?.assigneeId??"");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let User:UserType;
                User=docSnap.data();
                setAssigneeUser(User);
            } else {
                // doc.data() will be undefined in this case
            }
            }
            fetchUser().catch((e)=>{ErrorHandler(e, "fetchUser");})
            .then(()=>{setDatabaseStatusRequest("OK");});
        }

        // if(task.project?.projectID!==undefined)
        // {
        //     const fetchProjectsMemberships = async () => {
        //         const q = query(collection(db, "ProjectsMemberships"), where("projectId", "==", task.project?.projectID));
        //         const querySnapshot = await getDocs(q);
        //           let projectsMemberships:projectMembershipType[]=[];
        //           querySnapshot.docs.forEach(doc=>{
        //             let projectMembership:projectMembershipType;
        //             projectMembership=doc.data() as projectMembershipType;
        //             projectMembership.id = doc.id;
        //             projectsMemberships.push(projectMembership);
        //           })
        //           setProjectsMemberships(projectsMemberships)
        //         }
        //     fetchProjectsMemberships().catch((e)=>{ErrorHandler(e, "fetchProjectsMemberships");})
        //     .then(()=>{setDatabaseStatusRequest("OK");});
        // }
    }


    useEffect(
        ()=>{
            loadDB();
            const interval = setInterval(() => {
                saveDBEdit();
            },2*1000);
            return () => clearInterval(interval);
        },[]);


    useEffect(loadDBPostTaskLoad,[task]); 

    useEffect(()=>{console.log(Tasks)},[Tasks]); 

    function CreateTask(parentTaskID:string)
    {
        if(parentTaskID == undefined)
        {
            parentTaskID = "root"
        }

        let newTask:TaskType = {
            ParentNodeId: parentTaskID,
            title: "Task" + taskNumber,
            isDirty:true,
        }
        setTaskNumber(taskNumber+1);
        console.log(newTask)
        setTasks([...Tasks, newTask])
        setDatabaseStatusRequest("Sync");
    }

    async function saveDBEdit()
    {
        let activeCycle=false;

        TasksRef.current.forEach(async (task,i)=>{
            if(task.isDirty==true)
            {   
                activeCycle=true;
                if(task.id==undefined)
                {
                    await addDoc(collection(db, "Tasks"), task ).then((docRef)=>{
                        setTasks(TasksRef.current?.map((task,j)=>{if(i==j){task.isDirty=false; task.id=docRef.id}return task;})
                    )}).catch((e)=>{ErrorHandler(e, "Add Task");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
                else
                {
                    await setDoc(doc(db, "Tasks", task.id??""), task).then(()=>{
                        setTasks(TasksRef.current?.map((task,j)=>{if(i==j){task.isDirty=false; }return task;})
                    )}).catch((e)=>{ErrorHandler(e, "Edit Task");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
            }
        })


        if(activeCycle==false)
        {
            if(TasksRef?.current.some(task=>task.isDirty==true))
            {
                setDatabaseStatusRequest("DatabaseError");
            }
        }
    }

    function HandleReset(e:any)
    {
        loadDB();
    }

    function calcAvgRating()
    {
        let avgRating = 0.0;
        let sum = 0;
        let count = 0;
        // task?.ratingList?.map(ratingItem => 
        // {
        //     sum += ratingItem?.userRating??0;
        //     count++;
        // });
        avgRating = sum/(count);
        return avgRating;
    }

    function allowedStatus(currentState:string)
    {
        let statusList:string[] = [];
        if(currentState == "Unassigned")
        {
            statusList = ["Unassigned", "Active"];
        }
        else if (currentState == "Active")
        {
            statusList = ["Active", "InReview", "Blocked", "Failed"];
        }
        else if (currentState == "InReview")
        {
            statusList = ["InReview", "Done", "Active"];
        }
        else if (currentState == "Done")
        {
            statusList = ["Done"];
        }
        else if (currentState == "Blocked")
        {
            statusList = ["Blocked", "Active", "Failed"];
        }
        else if (currentState == "Failed")
        {
            statusList = ["Failed"];
        }
        return statusList;
    }

    async function ErrorHandler(error:Error, SourceFunction:string)
    {
      let errorLog:ErrorLogType = {file: "CreateEditTask", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }    
      setDatabaseStatusRequest("DatabaseError");
      setDatabaseErrorDesc(errorLog);
    }

    function FilterUserProjects()
    {
        let filteredProjectsNames = Projects.filter(filteredProject=> 
            (ProjectsMemberships?.find(membership=>((membership.projectId == filteredProject.id) && (membership.userId == currentUserId))) != undefined))
            .map(project=>(project.projectTitle));

        return filteredProjectsNames;
    }

    
    return(
        <Container>

            <Paper elevation={0} 
            sx={{
                p: 2,
                my: 2,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 5,
                alignItems:'center'
            }}>

                <Autocomplete
                sx={{width:'100%', m:1, alignSelf:'center'}}
                id="Project-search"
                options={FilterUserProjects()}
                readOnly={!(IsCreateNotEdit)}
                renderOption={(props, ProjectTitle) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <Typography sx={{m:1}} >{ProjectTitle}</Typography>
                    </Box>
                )}
                onChange={handleProjectSelection}
                renderInput={(params) => (
                <TextField {...params} label="Project" />
                )}
                />

                <Box
                sx={{
                opacity: task.project ? 1 : 0.3,
                pointerEvents: task.project ? 'auto' : 'none',
                transition: 'opacity 0.3s ease',
                padding: 2,
                border: '1px solid #ccc',
                }}
                >
                    <TaskTree Tasks={Tasks} CreateTaskFn={CreateTask}/>
                </Box>

                {/* <Box sx={{my:1}}>
                <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">Skill</InputLabel>
                    <Select
                    labelId="skill-select-label"
                    id="skill-select"
                    value={task?.skill||''}
                    label="skill"
                    onChange={handleSkillSelection}
                    //readOnly={!(IsCreateNotEdit)}
                    >
                        {Projects?.find(project=> project.id==task.project?.projectID)?.skillList?.map(skill => 
                        (<MenuItem value={skill}>{skill}</MenuItem>))}
                    </Select>
                </FormControl>   
                </Box> */}


                <TextField
                    id="outlined-helperText"
                    label="Title"
                    value={task?.title||''}
                    helperText=""
                    margin="none"
                    onChange={(e) => {
                        setTask({...task, title:e.target.value, isDirty:true});
                        setDatabaseStatusRequest("Sync");
                    }}
                    fullWidth
                    InputProps={{
                        //readOnly: !(IsCreateNotEdit)
                    }}
                />

                <Autocomplete
                sx={{width:'100%', m:1, alignSelf:'center'}}
                id="Skill-search"
                options={Projects?.find(project=> project.id==task.project?.projectID)?.skillList??[]}
                readOnly={!(IsCreateNotEdit)}
                renderOption={(props, skillTitle) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <Typography sx={{m:1}} >{skillTitle}</Typography>
                    </Box>
                )}
                onChange={handleSkillSelection}
                renderInput={(params) => (
                <TextField {...params} label="Skill" />
                )}
                />


                {/* <Box sx={{my:1}}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
                <Select
                labelId="assignee-select-label"
                id="assignee-select"
                value={task?.assignee?.assigneeName||''}
                label="project"
                onChange={handleAssigneeSelection}
                //readOnly={!(IsCreateNotEdit)} 
                >
                    {ProjectsMemberships?.map(member => 
                    (<MenuItem value={member.name}>{member.name}</MenuItem>))}
                </Select>
                </FormControl>   
                </Box>  */}

                {/* {(!IsCreateNotEdit)&&
                <Box sx={{my:1}}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                labelId="project-select-label"
                id="status-select"
                value={task?.status||''}
                label="status"
                onChange={handleStatusSelection}
                readOnly={!IsAssignee}
                >
                    {
                    allowedStatus(task?.status??"Unassigned").map(statusItem =>(
                        <MenuItem value={statusItem}>{statusItem}</MenuItem>
                    ))
                    }
                </Select>
                </FormControl>
                </Box>
                } */}



                <LocalizationProvider width={'100%'} dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                    label="Deadline"
                    inputFormat="dd/MM/yyyy"
                    value={task?.deadline||new Date()}
                    onChange={handleDeadlineChange}
                    renderInput={(params:any) => <TextField {...params} />}
                    //readOnly={!(IsCreateNotEdit)}
                    />
                </LocalizationProvider>
 
    
                {/* {(!IsCreateNotEdit)&&
                <Box sx={{my:1}}>
                <TextField
                    id="outlined-helperText"
                    label="Task Author"
                    value={task?.author?.authorName||''}
                    helperText=""
                    margin="none"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                />
                </Box>
                } */}


                <Box sx={{mt:2, mb:1}}>
                <TextField
                    id="outlined-helperText"
                    label="Supporting Link"
                    value={task?.link||''}
                    helperText="External link to supporting material (GitHub, Google Drive, etc.)"
                    margin="none"
                    onChange={(e) => {
                        setTask({...task, link:e.target.value, isDirty:true});
                        setDatabaseStatusRequest("Sync");
                    }}
                    fullWidth
                    InputProps={{
                        //readOnly: !(IsCreateNotEdit)
                    }}                
                />
                </Box>

                <Box sx={{mt:1}}>
                <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Description"
                    value={task?.description||''}
                    style={{ width: '100%', margin:"normal"}}
                    onChange={(e) => {
                        setTask({...task, description:e.target.value, isDirty:true});
                        setDatabaseStatusRequest("Sync");
                    }}
                    //readOnly= {!(IsCreateNotEdit)}
                />
                </Box>

                <Box sx={{border:1, borderRadius:1, borderColor:"gray", my:1, p:2, /*display:'flex', flexDirection:'row', justifyContent:'space-between'*/}}>
                <Typography sx={{mb:1, color:'gray'}}>Compensation</Typography>
                <Grid container spacing={0} rowSpacing={1} sx={{my:1}}>

                    <Grid item  xs={12} md={4} lg={4} >
                    <ToggleButtonGroup
                    color="primary"
                    value={task.compensation?.means}
                    exclusive
                    onChange={(e, value) => {
                        if(value!==null)
                        {
                            setTask({...task, compensation:{...task.compensation ,means:value}, isDirty:true});
                            setDatabaseStatusRequest("Sync");
                        }
                    }}
                    >
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="cash">Cash</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="equity">Equity</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="both">Both</ToggleButton>
                    </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4} >
                    <ToggleButtonGroup
                    color="primary"
                    value={task.compensation?.billing}
                    exclusive
                    onChange={(e, value) => {
                        if(value!==null)
                        {
                            setTask({...task, compensation:{...task.compensation ,billing:value}, isDirty:true});
                            setDatabaseStatusRequest("Sync");
                        }
                    }}
                    >
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="hourly">Hourly</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="fixed">Fixed</ToggleButton>
                    </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4} >
                    <Tooltip title="Full: Assignee will receive the full compensation amount upon completion of the task. Rating: Compensation amount will be scaled according to the rating project members gave to the task execution">
                    <ToggleButtonGroup
                    color="primary"
                    value={task.compensation?.calculation}
                    exclusive
                    onChange={(e, value) => {
                        if(value!==null)
                        {
                            setTask({...task, compensation:{...task.compensation ,calculation:value}, isDirty:true});
                            setDatabaseStatusRequest("Sync");
                        }
                    }}
                    >
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="full">Full</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="rating">Rating</ToggleButton>
                    </ToggleButtonGroup>
                    </Tooltip>
                    </Grid>

                </Grid>

                {((task.compensation?.means=="cash")||(task.compensation?.means=="both"))&&
                <TextField
                    sx={{mt:1}}
                    id="outlined-helperText"
                    label={"Allocated Cash ("+Projects.find((project:any)=>project.id==task.project?.projectID)?.currency+")"+((task.compensation?.billing=="hourly")?" per Hour":'')}
                    value={task?.compensation?.allocatedCash||''}
                    helperText=""
                    margin="none"
                    onChange={(e) => {
                        setTask({...task, compensation:{...task.compensation, allocatedCash: parseInt(e.target.value)}, isDirty:true});
                        setDatabaseStatusRequest("Sync");
                    }}
                    fullWidth
                    InputProps={{
                        //readOnly: !(IsCreateNotEdit)
                    }}                
                />}

                {((task.compensation?.means=="equity")||(task.compensation?.means=="both"))&&
                <TextField
                    sx={{mt:1}}
                    id="outlined-helperText"
                    label={"Allocated Shares"+((task.compensation?.billing=="hourly")?" per Hour":'')}
                    value={task?.compensation?.allocatedShares||''}
                    helperText=""
                    margin="none"
                    onChange={(e) => {
                        setTask({...task, compensation:{...task.compensation, allocatedShares: parseInt(e.target.value)}, isDirty:true});
                        setDatabaseStatusRequest("Sync");
                    }}
                    fullWidth
                    InputProps={{
                        //readOnly: !(IsCreateNotEdit)
                    }}                
                />}

                </Box>


                {/* {!IsCreateNotEdit&&IsMember&&(!IsAuthor)&&task.status=="Unassigned"&&
                <FormGroup>
                    <Box sx={{ alignItems: 'center', m:2}}>
                    <FormControlLabel control={
                    <Switch  
                    checked={((task?.requestList?.find(request=>(request.uid==currentUserId))!==undefined)? true:false)}
                    onChange={handleRequestTask}
                    size="medium"
                    />} label="Request Task" />
                    </Box>
                </FormGroup>
                } */}
                
            </Paper>
            
            {/* {IsAuthor&&(!IsCreateNotEdit)&&task?.assignee==undefined&&
            <Paper elevation={0} 
            sx={{
            p: 2,
            m:2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5
            }}
            >
            <Title>Task Requests</Title>

            <Table size="small">
                <TableBody>
                    {task.requestList?.map(request => {
                        return(
                            <TableRow >
                            <TableCell>{request.name}</TableCell>
                            <TableCell align="right"> 
                                <Button sx={{textTransform: 'none'}}
                                variant={(task?.assignee?.assigneeId==request.uid) ? "contained" :"outlined"}
                                onClick={() =>HandleAssignMember(request.uid??"", request.name??"")}                                     
                                >Assign Member</Button>  
                            </TableCell>
                        </TableRow>
                        )}
                    )}
                </TableBody>
            </Table>

        </Paper> }   */}

            {/* {(!IsCreateNotEdit)&&task.status=="InReview"&&
            <Paper elevation={0} 
            sx={{
                p: 2,
                m:2,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 5
            }}>

                <Typography sx={{m:2}}>Task is currently in review and may be rated, compensations will be moved to the assignee when the task status is updated to Done.</Typography>
                <Title>Task Rating</Title>

                <Box sx={{ mb:4, alignItems: 'center'}}>
                <Rating 
                    name="read-only" 
                    precision={0.1} 
                    value={calcAvgRating()} 
                    readOnly  
                />
                <Typography variant="subtitle2" margin="none">
                    {task?.ratingList?.length??0} Ratings
                </Typography>
                </Box>
                
        
                {(IsMember||IsAuthor)&&(!IsAssignee)&&
                <Box sx={{alignItems: 'center'}}>
                    <Title>Your rating of the Task</Title>
                    <Rating name="set-rating" 
                    precision={1} 
                    value={task?.ratingList?.find(ratingItem => (ratingItem.userId==currentUserId))?.userRating||null}
                    onChange={handleRatingChange}  
                    />
                </Box>
                }

                <Typography sx={{m:2}}>
                    Based on the current rating, compensations would be as follows.
                </Typography>
                <Box sx={{my:1}}>
                <TextField
                    id="outlined-helperText"
                    label="Cash Compensation"
                    value={(task?.cashCompensation??0) * ((calcAvgRating()/5)||0)}
                    fullWidth
                    InputProps={{
                        readOnly: true
                    }}                
                />
                </Box>

                <Box sx={{my:1}}>
                <TextField
                    id="outlined-helperText"
                    label="Equity compensation"
                    value={(task?.shareCompensation??0) * ((calcAvgRating()/5)||0)}
                    fullWidth
                    InputProps={{
                        readOnly: true
                    }}                 
                />
                </Box>
            </Paper>} */}

        </Container>
    );
}