import { Box, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ResponsiveSunburst } from '@nivo/sunburst'
import { EquityComponentType, projectMembershipType, TransactionType } from "./Interfaces";
import Title from "./Title";
import { useEffect, useState } from "react";


export default function EquityChart({ProjectsMemberships, Transactions, equityComponents}:
    {ProjectsMemberships:projectMembershipType[], Transactions:TransactionType[], equityComponents:EquityComponentType[]}) {

    const [SharesPercentages, setSharesPercentages] = useState<"Shares"|"Percentages">("Shares");
    const [CombinedEquityCompShares, setCombinedEquityCompShares] = 
    useState<{ProjecMembershipName:string, EquityCompName:string, shares:number}[]>([]);

    useEffect(
        ()=>{
            let CombinedEquityCompSharesBuffer: {ProjecMembershipName:string, EquityCompName:string, shares:number}[] = [];

            ProjectsMemberships.forEach(membership=>{
                equityComponents.forEach(comp=>{
                    
                    let CombinedEquityCompSharesItem:{ProjecMembershipName:string, EquityCompName:string, shares:number} = 
                    {} as {ProjecMembershipName:string, EquityCompName:string, shares:number};
                    CombinedEquityCompSharesItem.ProjecMembershipName = membership.memberName;
                    CombinedEquityCompSharesItem.EquityCompName = comp.name;
                    CombinedEquityCompSharesItem.shares = 0;

                    Transactions.forEach(transaction=>{
                        if((membership.memberName == transaction.firstParty.name) || 
                            (membership.memberName == transaction.secondParty.name))
                            {
                                if(transaction.equityComponent==comp.name)
                                {
                                    if(membership.memberName == transaction.firstParty.name)
                                    {
                                        if(transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.firstEntry.assetOrLiability=="asset")
                                        {
                                            CombinedEquityCompSharesItem.shares = CombinedEquityCompSharesItem.shares + transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.shareCount!;
                                        }
                                        else if (transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.firstEntry.assetOrLiability=="liability")
                                        {
                                            CombinedEquityCompSharesItem.shares = CombinedEquityCompSharesItem.shares - transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.shareCount!;
                                        }
                                    }
                                    else if(membership.memberName == transaction.secondParty.name)
                                    {
                                        if(transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.secondEntry.assetOrLiability=="asset")
                                        {
                                            CombinedEquityCompSharesItem.shares = CombinedEquityCompSharesItem.shares + transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.shareCount!;
                                        }
                                        else if (transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.secondEntry.assetOrLiability=="liability")
                                        {
                                            CombinedEquityCompSharesItem.shares = CombinedEquityCompSharesItem.shares - transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.shareCount!;
                                        }
                                    }
                                }            
                            }
                    })

                    CombinedEquityCompSharesBuffer.push(CombinedEquityCompSharesItem);
                })
            })
            setCombinedEquityCompShares(CombinedEquityCompSharesBuffer);            
        },[Transactions]);

        useEffect(
        ()=>{
            console.log(CombinedEquityCompShares)
        },[CombinedEquityCompShares]);


    return (
    <Paper elevation={0} 
    sx={{
        py: 2,
        my: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        alignItems:'center',
        justifyItems:'center'
        //height:350
    }}>

        <Title >Equity Distribution</Title>

        <ToggleButtonGroup
            sx={{alignSelf:'center', m:2, mb:5}}
            size="small"
            value={SharesPercentages}
            onChange={(event, value)=>{
                if(value!==null)
                {setSharesPercentages(value)}
            }}
            aria-label="text formatting"
            exclusive
            orientation= {"horizontal"}
            >
                <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="Shares" >Shares</ToggleButton>
                <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="Percentages">%</ToggleButton>
        </ToggleButtonGroup>

            
        <Box sx={{height:600, width:'80vw'}}>
            <ResponsiveSunburst
                data={{
                        name: "Equity",
                        children:
                            ProjectsMemberships.map(membership=>({
                                name: membership.memberName,
                                children:
                                CombinedEquityCompShares
                                .filter(comp=>( (comp.ProjecMembershipName==membership.memberName) && (comp.shares!=0)))
                                .map(comp=>
                                ({
                                    name: membership.memberName+": "+comp.EquityCompName,
                                    loc: comp.shares
                                }))
                            }))    
                    }}

                arcLabel={e=> SharesPercentages=="Shares" ? (e.id+" ("+e.value+")"): (e.id+" ("+e.percentage.toFixed(2)+" %)")}
                tooltip={e=> SharesPercentages=="Shares" ? <Typography>{e.id}: {e.value} shares</Typography>: <Typography>{e.id}: {e.percentage.toFixed(2)} %</Typography>}
                //arcLabelsRadiusOffset={0.5}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                id="name"
                value="loc"
                cornerRadius={20}
                borderWidth={4}
                borderColor={{ theme: 'background' }}
                colors={{ scheme: 'tableau10' }}
                childColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'opacity',
                            0.6
                        ]
                    ]
                }}
                enableArcLabels={true}
                arcLabelsSkipAngle={90}
                arcLabelsTextColor="black"
                />
        </Box>
    </Paper>    
);
}