import { Box, Button, Container, FormControlLabel, FormGroup, Grid, Paper, Rating, Switch, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
//import EquityPieChart from "./EquityPieChart";
import { Link, useParams } from 'react-router-dom';
import NeededSkills from "./NeededSkills";
import Progress from "./Progress";
import Ratings from "./ReviewPanel";
import ProjectCard from "./ProjectCard";
import CashDisplay from "./CashDisplay";
import { useEffect } from "react";
import useState from 'react-usestateref';
import {EquityOrderType, ErrorLogType, projectMembershipType, ProjectType, RatingItem, ReviewType, TaskType, TransactionType} from "./Interfaces";
import { auth, db } from "../firebase";
import { useGlobalState as useFirebaseGlobalState } from "./Settings" ;
import Title from "./Title";
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import EquityChart from "./EquityChart";
import DescriptionPanel from "./TaskDescriptionPanel";
import getCurrentLine from "get-current-line";
import CommentSection from "./CommentSection";
import React from "react";
import useWindowDimensions from "./useWindowDimensions";
import ReviewPanel from "./ReviewPanel";
import ProgressChart from "./ProgressChart";
import MarketView from "./MarketView";
import Orders from "./New folder/Orders";
import ProjectFinancialsChart from "./ProjectFinancialsChart";
import ProjectOverview from "./ProjectOverview";

export default function Project({setDatabaseStatusRequest, setDatabaseErrorDesc}:
  {setDatabaseStatusRequest:Function, setDatabaseErrorDesc:Function})
{
  let { id } = useParams<{id: string}>();
  const [project, setProject] = useState<ProjectType>({} as ProjectType);
  const [ProjectsMemberships, setProjectsMemberships, ProjectsMembershipsRef] = useState<projectMembershipType[]>([]);
  const [projectRatings, setProjectRatings, projectRatingsRef] = useState<ReviewType[]>([]);
  const [projectTasksRatings, setProjectTasksRatings, projectTasksRatingsRef] = useState<ReviewType[]>([]);
  const [Tasks, setTasks] = useState<TaskType[]>();
  const [Transactions, setTransactions] = useState<TransactionType[]>([]);
  const [NavigationValues, setNavigationValues] = React.useState<string[]>(() => ["Overview"]);
  const [EquityOrders, setEquityOrders] = React.useState<EquityOrderType[]>();

  const [user, loading, error] = useAuthState(auth);
  let currentUserId = user?.uid;
  let currentUserName = user?.displayName??"";
  useEffect(() =>{
      currentUserId = user?.uid;
      currentUserName = user?.displayName??"";
  },
  [user]
  );


useEffect(() =>{
  fetchProjectTasksRatings().catch((e)=>{ErrorHandler(e, "fetchProjectTasksRatings");}).then(()=>{setDatabaseStatusRequest("OK");});
  },
  [Tasks]
  );

  
useEffect(() =>{
  },
  [projectTasksRatings]
  );

  const [useFirebase] = useFirebaseGlobalState('useFirebase');
  
  const [saveButtonText, setsaveButtonText] = useState<string>("Save");
  const [saveButtonState, setsaveButtonState] = useState<("primary" | "success")>("primary");

  const IsAdmin = (ProjectsMemberships?.find(member => (member.userId == currentUserId))?.role=="Admin")? true:false;
  const IsContributer = (ProjectsMemberships?.find(member => (member.userId == currentUserId)))?.role == "Contributer" ? true:false;
  const IsRequesting = (ProjectsMemberships?.find(member => (member.userId == currentUserId)))?.status == "request" ? true:false;
  const IsMember = IsAdmin||IsContributer;

  let helpMessage = "";
  let helpDescription = "";
  if(IsAdmin)
  {
      helpMessage = "Project Admin";
      helpDescription = "You are an admin of this project, you can update project settings.";
  }
  else if(IsRequesting)
  {
      helpMessage = "Request Pending";
      helpDescription = "Your request to join the project is pending.";
  }
  else if(IsContributer)
  {
      helpMessage = "Project Member";
      helpDescription = "You are a project contributer, you may request tasks or rate the project.";
  }
  else if(!IsContributer)
  {
      helpMessage = "Not a member";
      helpDescription = "You are not a member of this project, you may request membership.";
  }

  const handleRequestMembership = () => {

    //If the user is already a member, toggle the status between request and removed
    if(ProjectsMemberships.find(membership=>(membership.userId==currentUserId)))
    {
      setProjectsMemberships(
        ProjectsMemberships.map(membership=>{
          if(membership.userId==currentUserId)
          {
            if(membership.status=="removed")
            {
              membership.status="request";
            }
            else if(membership.status=="request")
            {
              membership.status="removed";
            }
            membership.isDirty = true;
            setDatabaseStatusRequest("Sync");
          }
          return membership;
        }));
    }
    //If the user is not a member, add a new membership request
    else
    {
      let newMemberItem:projectMembershipType = {userId: currentUserId??"", memberName: currentUserName??"", projectId:project.id, status:"request", isDirty:true};
      setProjectsMemberships([...ProjectsMemberships, newMemberItem ]);
      setDatabaseStatusRequest("Sync");
    }

  }

  const handleRequestShareAsk = (e:any) => {

    setProjectsMemberships(
      ProjectsMemberships.map(membership=>{
        if(membership.userId==currentUserId)
        {
          membership.sharesRequested = parseInt(e.target.value);
          membership.isDirty = true;
          setDatabaseStatusRequest("Sync");
        }
        return membership;
      }));

  }

  const handleRatingChange = (e:any) => {
        
    //Create new rating
    if(!projectRatings.find(rating=>( (rating.authorId==currentUserId) && (rating.targetId==id) )))
    {
        let ratingItem:ReviewType={authorId:currentUserId, targetId:id, Rating:parseInt(e.target.value), isDirty:true}
        setProjectRatings([...projectRatings, ratingItem]);
        setDatabaseStatusRequest("Sync");
    }
    //Modify old rating
    else
    {
        setProjectRatings(
          projectRatings?.map(ratingItem=>
                {
                    if(ratingItem.authorId==currentUserId && ratingItem.targetId==id)
                    {
                        ratingItem.Rating = parseInt(e.target.value);
                        ratingItem.isDirty = true;
                        setDatabaseStatusRequest("Sync");
                    }
                    return ratingItem;
                }));
    }
};
  
  function calcAvgProjectRating()
  {
      let avgRating = 0.0;
      let sum = 0;
      let count = 0;
      projectRatings.filter(ratingItem=>ratingItem.targetId==id).forEach(ratingItem=>
      {
          sum += ratingItem?.Rating??0;
          count++;
      });
      avgRating = sum/(count);
      return avgRating;
  }

  function calcAvgProjectTasksRating()
  {
    let avgRating = 0.0;
    let sum = 0;
    let count = 0;
    projectTasksRatings.forEach(ratingItem=>
    {
        sum += ratingItem?.Rating??0;
        count++;
    });
    avgRating = sum/(count);
    return avgRating;
  }

  async function saveDBEdit()
  {
    let activeCycle=false;
    let successfulCycle=false;
    ProjectsMembershipsRef.current.forEach(async (membership,i)=>{
      if(membership.isDirty==true)
      {          
        activeCycle=true;
          if(membership.id==undefined)
          {
              await addDoc(collection(db, "ProjectsMemberships"), membership ).then((docRef)=>{
                  setProjectsMemberships(ProjectsMembershipsRef.current?.map((membership,j)=>{if(i==j){membership.id=docRef.id; membership.isDirty=false;}return membership;})
              )}).catch((e)=>{ErrorHandler(e, "Add Project Membership")}).then(()=>{setDatabaseStatusRequest("OK");});
          }
          else
          {
              await setDoc(doc(db, "ProjectsMemberships", membership.id??""), membership).then(()=>{
                  setProjectsMemberships(ProjectsMembershipsRef.current?.map((membership,j)=>{if(i==j){membership.isDirty=false;}return membership;})
              )}).catch((e)=>{ErrorHandler(e, "Set Project Membership")}).then(()=>{setDatabaseStatusRequest("OK");});
          }
      }
    })

    projectRatingsRef?.current.forEach(async (rating, i)=>{
      if(rating.isDirty==true)
      {
          activeCycle=true;
          if(rating.id)
          {
              await setDoc(doc(db, "ProjectRatings", rating.id??""), rating).then(()=>{
                setProjectRatings(projectRatingsRef?.current.map((rating, j)=> {if(i==j){rating.isDirty=false;} return rating;}))
          }).catch((e)=>{ErrorHandler(e, "Set Project Rating")}).then(()=>{setDatabaseStatusRequest("OK");});
          }
          else
          {
              await addDoc(collection(db, "ProjectRatings"), rating ).then(()=>{ 
                setProjectRatings(projectRatingsRef?.current.map((rating, j)=> {if(i==j){rating.isDirty=false;} return rating;}))
              }).catch((e)=>{ErrorHandler(e, "Add Project Rating")}).then(()=>{setDatabaseStatusRequest("OK");});
          }
      }
  })

    
    if(activeCycle==false)
    {
      if(ProjectsMembershipsRef.current.some(membership=>membership.isDirty==true))
      {
        setDatabaseStatusRequest("DatabaseError");
      }
      else if(projectRatingsRef?.current.some(rating=>rating.isDirty==true))
      {
        setDatabaseStatusRequest("DatabaseError");
      }
    }

  }

  useEffect(
    ()=>{
        loadDB();
        const interval = setInterval(() => {
          saveDBEdit();
        },2*1000);
        return () => clearInterval(interval);
    },[]);

  function loadDB()
  {
    const fetchProject = async () => {
      const docRef = doc(db, "Projects", id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        let Project:ProjectType;
        Project=docSnap.data() as ProjectType;
        Project.id = docSnap.id;
        Project.isDirty = false;
        setProject(Project);
      } else {
        // doc.data() will be undefined in this case
      }
    }
    fetchProject().catch((e)=>{ErrorHandler(e, "Fetch Project")}).then(()=>{setDatabaseStatusRequest("OK");});

 
    const fetchProjectsMemberships = async () => {
      const q = query(collection(db, "ProjectsMemberships"), where("projectId", "==", id));
      const querySnapshot = await getDocs(q);
        let projectsMemberships:projectMembershipType[]=[];
        querySnapshot.docs.forEach(doc=>{
          let projectMembership:projectMembershipType;
          projectMembership=doc.data() as projectMembershipType;
          projectMembership.id = doc.id;
          projectMembership.isDirty = false;
          projectsMemberships.push(projectMembership);
        })
        setProjectsMemberships(projectsMemberships)
      }
    fetchProjectsMemberships().catch((e)=>{ErrorHandler(e, "Fetch Project Memberships")}).then(()=>{setDatabaseStatusRequest("OK");});


    const fetchTasks = async () => {
      const q = query(collection(db, "Tasks"), where("project.projectID", "==", id));
      const querySnapshot = await getDocs(q);

      let Tasks:TaskType[]=[];
      querySnapshot.docs.forEach(doc=>{
        let Task:TaskType;
        Task=doc.data();
        Task.id = doc.id;
        Task.isDirty = false;
        Tasks.push(Task);
      })
      setTasks(Tasks);
    }
    fetchTasks().catch((e)=>{ErrorHandler(e, "Fetch Tasks")}).then(()=>{setDatabaseStatusRequest("OK");});


    const fetchProjectRatings = async () => {
      const q = query(collection(db, "ProjectRatings"), where("targetId", "==", id));
      const querySnapshot = await getDocs(q);

      let ProjectRatings:ReviewType[]=[];
      querySnapshot.docs.forEach(doc=>{
          let Rating:ReviewType;
          Rating=doc.data();
          Rating.id = doc.id;
          Rating.isDirty = false;
          ProjectRatings.push(Rating);
      })
      setProjectRatings(ProjectRatings);
      }
      fetchProjectRatings().catch((e)=>{ErrorHandler(e, "Fetch Project Ratings")}).then(()=>{setDatabaseStatusRequest("OK");});

      const fetchTransactions = async () => {
        const q = query(collection(db, "Transactions"), where("projectId", "==", id));
        const querySnapshot = await getDocs(q);
  
        let Transactions:TransactionType[]=[];

        querySnapshot.docs.forEach(doc=>{
            let Transaction:TransactionType;
            Transaction=doc.data() as TransactionType;
            Transaction.id = doc.id;
            Transaction.isDirty = false;
            Transactions.push(Transaction);
        })
        setTransactions(Transactions);
        }
        fetchTransactions().catch((e)=>{ErrorHandler(e, "Fetch Transactions")}).then(()=>{setDatabaseStatusRequest("OK");});

        const fetchMarketOrders = async () => {
          const q = query(collection(db, "EquityOrders"), where("projectId", "==", id), where("status", "==", "Active"));
          const querySnapshot = await getDocs(q);
            let MarketOrders:EquityOrderType[]=[];
            querySnapshot.docs.forEach(doc=>{
              let order:EquityOrderType;
              order=doc.data();
              order.id = doc.id;
              order.isDirty = false;
              MarketOrders.push(order);
            })
            setEquityOrders(MarketOrders);
          }
          fetchMarketOrders().catch((e)=>{ErrorHandler(e, "Fetch MarketOrders")}).then(()=>{setDatabaseStatusRequest("OK");});
  }

  
  const fetchProjectTasksRatings = async () => {
    let TasksIdsList=Tasks?.map(task=>task.id);

    const q = query(collection(db, "TaskRatings")/*, where('targetId', 'in', TasksIdsList)*/);
    const querySnapshot = await getDocs(q);

    let ProjectTasksRatings:ReviewType[]=[];
    querySnapshot.docs.forEach(doc=>{
        let Rating:ReviewType;
        Rating=doc.data();
        Rating.id = doc.id;
        Rating.isDirty = false;
        if(Rating.targetId && TasksIdsList?.includes(Rating.targetId))
        {
          ProjectTasksRatings.push(Rating);
        }
        
    })
    setProjectTasksRatings(ProjectTasksRatings);
    }

    async function ErrorHandler(error:Error, SourceFunction:string)
    {
      let errorLog:ErrorLogType = {file: "Project", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
      setDatabaseStatusRequest("DatabaseError");
      setDatabaseErrorDesc(errorLog);
    }

    return(
        <Container> 

          <Tooltip title={helpDescription} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
          <Paper elevation={0} 
          sx={{
              py: 2,
              my: 2,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 5,
              ":hover": {boxShadow: 15},
          }}>
            
              <Typography ><strong>{helpMessage}</strong></Typography>
              
              {IsAdmin&&
              <Button 
              sx={{textTransform: 'none', m: 2 , minWidth:'10%',alignSelf: 'center'}} 
              variant="contained" 
              component={(props:any) => <Link {...props} to={"/Home/EditProject/"+id} />} >
                Edit Project
              </Button>}

              {!IsMember&&
              <FormGroup>
              <Box sx={{ alignItems: 'center', m:2}}>
              <FormControlLabel control={
              <Switch  
              checked={(ProjectsMemberships?.find(member => member.userId==currentUserId)?.status == "request" ? true:false)}
              onChange={handleRequestMembership}
              size="medium"
              />} label="Request Membership" />
              </Box>
              </FormGroup>}


              {!IsMember&&
              (ProjectsMemberships?.find(member => member.userId==currentUserId)?.status == "request")&&
              <Box sx={{my:1}}>
                  <TextField
                      id="outlined-helperText"
                      label="Equity Ask"
                      value={ProjectsMemberships?.find(member => member.userId==currentUserId)?.sharesRequested||0}
                      helperText="Shares you ask for as an initial compensation to join the project"
                      margin="none"
                      onChange={handleRequestShareAsk}             
                  />
                </Box>
              }
              
            </Paper>
            </Tooltip>

            <Paper elevation={0} 
                    sx={{
                        py: 2,
                        my: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 5,
                        alignItems: 'center'
                    }}>

                  <ToggleButtonGroup
                  exclusive
                  value={NavigationValues}
                  onChange={(event, values)=>{setNavigationValues(values); loadDB();}}
                  aria-label="text formatting"
                  orientation= {(useWindowDimensions().width<800)? "vertical":"horizontal"}
                  >
                      <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Overview">Overview</ToggleButton>
                      <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Equity" >Equity</ToggleButton>
                      <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Financials" >Financials</ToggleButton>
                      <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Progress" >Progress</ToggleButton>
                      <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Market" disabled={false} >Market</ToggleButton>
                      <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Reviews" disabled={false}>Reviews</ToggleButton>
                      <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Comments"  >Comments</ToggleButton>
                  </ToggleButtonGroup>

              </Paper>


            {NavigationValues.includes("Financials")&&
              <ProjectFinancialsChart Transactions={Transactions} Orders={EquityOrders!}/>
            }

            {NavigationValues.includes("Progress")&&
              <ProgressChart ProjectId={project.id!} />
            }
         
            {NavigationValues.includes("Equity")&&
              <EquityChart ProjectsMemberships={ProjectsMemberships} Transactions={Transactions} equityComponents={project.equityComponentList!} />
            }


            {NavigationValues.includes("Market")&&
              <MarketView projectId={project.id!} currency={project.currency!} ProjectsMemberships={ProjectsMemberships} 
              Transactions={Transactions} setDatabaseStatusRequest={setDatabaseStatusRequest} 
              setDatabaseErrorDesc={setDatabaseErrorDesc} />
            }


            {NavigationValues.includes("Reviews") && 
              <ReviewPanel TargetId={project.id??""}
              setDatabaseStatusRequest={setDatabaseStatusRequest} 
              setDatabaseErrorDesc={setDatabaseErrorDesc}/>
            }


            {NavigationValues.includes("Overview")&&
              <ProjectOverview project={project} transactions={Transactions} projectMemberships={ProjectsMemberships}/>
            }

            {NavigationValues.includes("Comments")&&
              <CommentSection itemID={project.id!}
              setDatabaseStatusRequest={setDatabaseStatusRequest} 
              setDatabaseErrorDesc={setDatabaseErrorDesc}/>
            }


        </Container>
    );
}