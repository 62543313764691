import { AlignHorizontalCenter } from '@mui/icons-material'
import { Box, Container, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { auth, db } from '../firebase';
import { ProjectType, TaskType } from './Interfaces';
import Title from './Title'
import { useGlobalState as useFirebaseGlobalState } from "./Settings" ;
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

function Reports() {

    const [Tasks, setTasks] = React.useState<TaskType[]>();

    const [useFirebase] = useFirebaseGlobalState('useFirebase');

    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";
    useEffect(() =>{
        currentUserId = user?.uid;
        currentUserName = user?.displayName??"";
    },
    [user]
    );
    
    React.useEffect(() =>{

        if(useFirebase)
        {
          const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "Tasks"));
    
            let Tasks:TaskType[]=[];
            querySnapshot.docs.forEach(doc=>{
              let Task:TaskType;
              Task=doc.data();
              Task.id = doc.id;
              Tasks.push(Task);
            })
            setTasks(Tasks);
          }
    
          fetchData().catch(console.error);   
        }
        else
        {
          fetch('http://localhost:3001/Tasks')
          .then(res => res.json())
          .then(data => setTasks(data));
        }
    },
    []
    );


    function calcAvgTasksRating(TaskId:string)
    {
        let avgRating = 0.0;
        let sum = 0;
        let count = 0;

        // Tasks?.find(Task=> Task.id==TaskId)?.ratingList?.forEach(ratingItem => 
        //     {
        //         sum += ratingItem?.userRating??0;
        //         count++;
        //     });

        // avgRating = sum/(count);

        return avgRating;
    }


    return (
<Container sx={{mt:2}}>

    <Title>Compensation Transactions History</Title>

    <Paper elevation={0} 
    sx={{
        p: 2,
        my:2,
        overflow: 'scroll',
        borderRadius: 5
    }}>
        <Table size="small">
        <TableHead>
            <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Project</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Task Author</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Task Assignee</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Task Title</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Cash Compensation</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Share Compensation</TableCell>

            <TableCell style={{ fontWeight: 'bold' }} align="right">Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {Tasks?.filter(task=> task.assignee?.assigneeId==currentUserId)
            .filter(task=> (task.status=="Done"/*||task.status=="PaymentConfirmed"*/))
            .map((task) => (
            <TableRow component={props => <Link {...props} to={"/Home/Task/"+task.id} />} key={task.id}>
                <TableCell >{task?.project?.projectName}</TableCell>
                <TableCell >{task?.author?.authorName}</TableCell>
                <TableCell >{task?.assignee?.assigneeName}</TableCell>
                <TableCell>{task?.title}</TableCell>
                <TableCell>{(task?.compensation?.allocatedCash??0) * calcAvgTasksRating(task.id??"")/5}</TableCell>
                <TableCell>{(task?.compensation?.allocatedShares??0) * calcAvgTasksRating(task.id??"")/5}</TableCell>
                <TableCell align="right">{task?.status}</TableCell>
            </TableRow>
            ))}
        </TableBody>
        </Table>
    </Paper>
</Container>
    )
}

export default Reports