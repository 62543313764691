import { Paper, Typography } from "@mui/material";
import { projectMembershipType, ProjectType, TransactionType } from "./Interfaces";

export default function ProjectOverview({project, transactions, projectMemberships}:
    {project:ProjectType, transactions:TransactionType[], projectMemberships:projectMembershipType[]})
{
    return (
        <Paper sx={{display:'flex', flexDirection:'column', alignItems:'start', p:2}}>


            <Typography color={"darkgray"}>
                <strong>Project Title</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3, textTransform:'none'}}>
                {project.projectTitle}
            </Typography>

            <Typography color={"darkgray"}>
                <strong>Categroy</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3}}>
                {project.category}
            </Typography>

            <Typography color={"darkgray"}>
                <strong>Description</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" textAlign="left" sx={{mb:3}}>
                {project.description}
            </Typography>
            
            <Typography color={"darkgray"}>
                <strong>Skill list</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3}}>
                {project.skillList?.map((skill, i)=> (i<((project.skillList?.length??0) - 1))?(skill + ", "): skill)}.
            </Typography>

            <Typography color={"darkgray"}>
                <strong>Initial valuation</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3}}>
                {project.initialValuation} {project.currency}
            </Typography>

            <Typography color={"darkgray"}>
                <strong>Current valuation</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3}}>
                {transactions.sort((a,b)=>{return (Date.parse(a.date!)-Date.parse(b.date!))}).at(-1)?.valuation.toFixed(2)} {project.currency}
            </Typography>

            <Typography color={"darkgray"}>
                <strong>Memberships</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3}}>
                {projectMemberships.length} Members, {projectMemberships.filter(membership=>(membership.role=="Admin")).length} of which are Admins.  
            </Typography>

            <Typography color={"darkgray"}>
                <strong>Current Cash Balance</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3}}>
                {transactions
                .filter(transaction=>(transaction.equityComponent=="Cash"))
                .filter(transaction=>(transaction.firstParty.type=="project"))
                .reduce((sum:number, value)=>{
                    if(value.balancesheetEntryPairs.find(entry=>(entry.firstEntry.assetOrLiability=="asset")))
                    {
                        sum = sum + (value.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="cash"))?.cashValue??0); 
                    }
                    else if (value.balancesheetEntryPairs.find(entry=>(entry.secondEntry.assetOrLiability=="liability")))
                    {
                        sum = sum - (value.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="cash"))?.cashValue??0); 
                    }
                    return sum;
                }, 0).toFixed(2)} {project.currency} 
            </Typography>

            <Typography color={"darkgray"}>
                <strong>Distributed Cash</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3}}>
                {}  
            </Typography>

            <Typography color={"darkgray"}>
                <strong>Created</strong>
            </Typography>
            <Typography variant="subtitle1" fontFamily="-moz-initial" sx={{mb:3}}>
                {project.created}  
            </Typography>

        </Paper>
    )
}