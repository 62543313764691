import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { TaskType } from "./Interfaces";
import Title from "./Title";
import { useEffect, useState } from "react";

export default function TaskDescriptionPanel({Task, currency}:{Task:TaskType, currency:string})
{

    const [CompensationText, SetCompensationText] = useState<string>();



    useEffect(() =>{
        let CompensationTextTemp = "";
        switch(Task.compensation?.means)
        {
            case "cash":
                CompensationTextTemp += "Cash (" + Task.compensation.allocatedCash + " "+currency+")";
                break;
            case "equity":
                CompensationTextTemp += "Equity (" + Task.compensation.allocatedShares + " shares)";
                break;
            case "both":
                CompensationTextTemp += "Cash (" + Task.compensation.allocatedCash + " "+currency+") and Equity (" + Task.compensation.allocatedShares + " shares)";
                break;
        }
        switch(Task.compensation?.billing)
        {
            case "hourly":
                CompensationTextTemp += " per hour";
                break;
        }
        switch(Task.compensation?.calculation)
        {
            case "full":
                CompensationTextTemp += ", task rating will not affect compensation.";
                break;
            case "rating":
                CompensationTextTemp += ", task rating will affect compensation.";
                break;
        }


        SetCompensationText(CompensationTextTemp);
    },
    [currency]
    );



    return(

        <Tooltip title="Task details are not editable while the task is assigned because a task constitutes an agreement between the author and the assignee and should not be modified mid-execution." enterTouchDelay={0} leaveTouchDelay={5000} arrow>

        <Paper elevation={0} 
        sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            alignItems: 'canter',
            ":hover": {boxShadow: 15},
        }}>
            <Title>Task Details</Title>

        <Paper elevation={0} 
        sx={{
            p: 1    ,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            alignItems: 'start',
            //":hover": {boxShadow: 15},
        }}>


            <Typography sx={{mx:3, my:1, fontSize:'min(2.5vw,15px)'}}><strong>Title:</strong> {Task.title}</Typography>
            <Typography sx={{mx:3, my:1, fontSize:'min(2.5vw,15px)'}}><strong>Skill:</strong> {Task.skill}</Typography>
            <Typography sx={{mx:3, my:1, fontSize:'min(2.5vw,15px)'}}><strong>Author:</strong> {Task.author?.authorName}</Typography>
            <Typography sx={{mx:3, my:1, fontSize:'min(2.5vw,15px)'}}><strong>Assignee:</strong> {Task.assignee?.assigneeName??"Unassigned"}</Typography>
            <Typography sx={{mx:3, my:1, fontSize:'min(2.5vw,15px)'}}><strong>Supporting Link:</strong> {Task.link}</Typography>
            <Typography sx={{mx:3, my:1, fontSize:'min(2.5vw,15px)'}}><strong>Created on:</strong> {Task.created}</Typography>
            <Typography sx={{mx:3, my:1, fontSize:'min(2.5vw,15px)'}}><strong>Deadline:</strong> {Task.deadline}</Typography>
            <Typography sx={{mx:3, my:1, fontSize:'min(2.5vw,15px)'}}><strong>Compensation: </strong> {CompensationText}</Typography>

            

            <Box sx={{ mx:3, my:1, border:1, borderRadius:5, borderColor:"#7196C1", minWidth:'50vw', alignSelf:'center', alignItems: 'center', }}>
            <Typography sx={{mx:3, my:1}}><strong>Description</strong></Typography>
            <Typography sx={{mx:1, my:1, fontSize:'min(2.5vw,15px)'}}>
                {Task.description}
            </Typography>
            </Box>

        </Paper>    
        </Paper>   
        </Tooltip>
    );
}